.misc-pax-info{
	
	.misc-pax-info{
	
		&__container{
			@include clearfix;
			
			.disabled{
				cursor: not-allowed;
			}
			&_content{
				border-top: 1px solid $gray-lighting;
				.misc-pax-row {
					padding: .5rem 0;
				
					@include clearfix();
					&__pax-row{
						float: left;
    					width: 90%;
					}
					&__adult-data,&__child-data,&__infant-data{
						width:100%;
						float: left;
						position: relative;
						.pax-info-field{
							width:90%;
							float: left;
						}
						.pax-info-type,
						.pax-info-title,
						.pax-info-first-name,
						.pax-info-last-name,
						.pax-info-dob,
						.pax-info-email{
							float: left;
							padding-left: 0.25rem;
    						padding-right: 0.25rem;
    						min-height: 4.75rem;
						}
						.pax-info-type{
							width: 8%;
    						padding-top: 1.5rem;
						}
						.pax-info-title{
							width: 15%;
							
							&__dropdown {
								float:left;
								width:100%;
							}
							&__label {
								float:left;
							}
						}
						.pax-info-email{
							width: 20%;
							float: left;
							&__label {
								float:left;
							}
						}
						.pax-info-first-name,
						.pax-info-last-name,
						.pax-info-dob {
							&__textbox{
								float:left;
								width: 100%;
							}
							&__label{
								float:left;
							}
						}
						
						.pax-info-first-name,
						.pax-info-last-name {
							width: 20%;
						}
						.pax-info-dob {
							width: 25%;
						}
					}
					&__remove {
						float:left;
						padding: .25rem;
						padding-top: 1.925rem;
						
						>button {
				    		background-color: $light;
						}
						>button:focus, :hover {
				    		>span {
				    			color: $brand-color-1;
			    			}
						}
						.remove-button {
							&__icon {
								color: $gray-light;
								font-size: 1.25rem;
							}
						}
					}
					&__add {
						@include make-round-button($brand-color-2, 30px); 
						float:left;
						font-size: 1.25rem;
						padding-top: 1.5rem;
						margin-left: .25rem;
					}
					
					&__no-of-pax-data{
						padding-left: 5.8125rem;
						&_item{
							width: 20%;
							float: left;
							padding: 0 0.25rem;
						}
					}
				}
			}
			.misc-pax-traveler-selection{
				&__add-traveler,&__select-from-contact-center{
				    @include clearfix;
					width: 100%;
 	 				margin : $base-margin*0.5 0;        
        		
		        	>button {
						@include button-size(0.3125rem, 0 , $font-size-h4 , inherit, 0);
						@include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20)); 
						border: none;
						width: 20%;	
						font-weight: 700;
						float: right;
						margin-left: 1rem;
					}
					&_note{
						color: $brand-color-30;
						float: left;
						width:70%;
						float: left;
					    text-align: left;
					    vertical-align: middle;
					    font-size: 0.9375rem;
					    padding-left: 0.9375rem;
					    font-weight: 700;
					    text-transform: capitalize;					
					}
				}
				&__select-from-contact-center{
					float: left;
				}
			}
		}
	}
}