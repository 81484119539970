@import "../../../../../stylesheets/modules_new/styles/components/miscellaneous/products/other-service/misc-other-service-container";

.misc-other-service-container {
    @include make-miscellaneous-form;
    @include make-input;
    @include hide-number-spinner;
    .footer{
	        &__inclusions, &__exclusions, &__remarks {
		       		float:left;
	        		width: 25%;
	        		padding: 0.25rem 0 0.25rem 1rem;
	        		min-height: 5.5rem;
        	}
			&__action-buttons{
					display: table;
					content: '';
					width: 100%;
			}        	
		}
		.gv-quantity{
			
			&__label{
			    width: 68%;
			}
			
			&__select{
			    width: 32%;
			}
		}
   
}