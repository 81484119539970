@import "../../../../../../../../../stylesheets/modules_new/styles/components/miscellaneous/products/air/forms/miscellaneous-air-pricing-info/fare-info";


.fare-info {
	margin-top: 1rem;

	&__pricing-form {
	
		&_header {
			@include clearfix;
			border-bottom: 1px solid $gray-lighting;
			margin-bottom: .5rem;
			width: 100%;
			
			.pricing-form-header {
				&__pax-type {
					color: $gray;
					float: left;
					font-size: 1.125rem;
				}
				&__total-amount {
					float: right;
					
					&_currency {
		    			font-size: 0.75rem;
		    		}
		    		&_value {
				    	color: $brand-color-1;
				    	font-family: $font-family-number;
						font-size: 1.125rem;
		    		}
				}
			}
		}
		
		&_content {
			@include clearfix;
			position: relative;
			width: 100%;
			
			.pricing-form-content {
				&__base-fare {
					float: left;
					width: 20%;
					
					input {
						font-family: $font-family-number;
					}
				}
				
				&__taxes {
					float: left;
					width: 30%;
					padding-left: .5rem;
				}
				
				&__extra-pricing {
					float: left;
					width: 50%;
					padding-left: .5rem;
				}
			}
		}
	
		&_pricing-details-content {
			@include clearfix;
			background-color: $body-bg;
			float: left;
			width: 75%;
			padding: .75rem 0;
		
			.pricing-details-content {
				&__transaction-fee,
				&__markup,
				&__comm-passon,
				&__orc-passon,
				&__plb-passon,
				&__comm-received,
				&__orc-received,
				&__plb-received,
				&__discount-rebate,
				&__service-tax,
				&__input-vat,
				&__general-tax {
					@include make-one-fourth;
				}
			}
		}
	}
	
}