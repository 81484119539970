.tax-component {
	&__header {
		color: $brand-color-2;
		font-size: .875rem;
	}

	&__total-tax-amount-container {
		@include clearfix;
		border: 1px solid $gray-lighting;
		cursor: pointer;
		font-size: 1rem;
		height: 34px;
		padding: .25rem;
		
		&_total-tax-label {
			float: left;
		}
		
		&_total-tax-amount {
			float: right;
			
			.total-tax-amount {
				&__value {
					color: $brand-color-1;
					float: left;
					font-family: $font-family-number;
				}
				&__toggle-icon {
					@include make-round-button($brand-color-2, 1.5625rem);
					
					float: left;
					margin-left: .25rem;
					
					>button:focus, :hover {
						font-size: 1rem;
					}
				}
			}
		
		}
	}

	&__content {
		border: 1px solid $gray-lighting;
		border-top: 0;
		padding: .25rem;
		
		&_labels {
			@include clearfix;
			color: $brand-color-2;
			cursor: pointer;
			font-size: 0.875rem;
			
			.labels {
				&__tax-code,
				&__tax-amount {
					float: left;
					width: 30%;
				}
			}
		}
		
		&_tax-details {
			@include clearfix;
			width: 100%;
			margin-bottom: 0.25rem;
			
			.tax-details {
				&__tax-code,
				&__tax-amount,
				&__add-more,
				&__remove {
					float: left;
				}
				
				&__tax-code {
					width: 30%;
					padding-right: .9375rem;
				}
				
				&__tax-amount {
					width: 35%;
					padding-right: .9375rem;
					
					input {
						font-family: $font-family-number;
					}
				}
				
				&__remove {
					@include delete-icon;
					padding: .25rem;
					margin-left: -1rem;
				}
				
				&__add-more {
					@include make-round-button($brand-color-2, 1.5625rem);
					margin-top: .25rem;
				}
			}
		}
		
	}
}