@import "../../../../../stylesheets/modules_new/styles/components/miscellaneous/miscellaneous-mixins/miscellaneous-pricing-form";


@mixin make-miscellaneous-pricing-form_private {
	
	border-top: 1px solid $gray-lighting;
	padding-top: .625rem;
    &__wrapper {
    	@include clearfix;
        padding-top: .25rem;
    	width: 100%;
        
        &_supplier-info-container {
	    	@include clearfix;
	    	float: left;
	    	width: 50%;
	    	
        	.supplier-info-container {
        		&__supplier-list {
       				float: left;
        			width: 47%;
        			&_dropdown-disabled{
						background-color: $gray-lightest;
						cursor: not-allowed;
					}
        		}
        		
        		&__supplier-currency, &__supplier-ref-no, &__room-list,&__currency_rate {
       				float: left;
       				margin-left: 1rem; 
        			width: 20%;
        			
        			&_value {
        				font-size: 1.25rem;
        			}
				}
				&__tagging-container {
	
					border: 1px solid #d0d0d0;
					
				   .ui-select-highlight {
					 font-weight: bold;
				   }
				   
				   .ui-select-offscreen {
					 clip: rect(0 0 0 0) !important;
					 width: 1px !important;
					 height: 1px !important;
					 border: 0 !important;
					 margin: 0 !important;
					 padding: 0 !important;
					 overflow: hidden !important;
					 position: absolute !important;
					 outline: 0 !important;
					 left: 0px !important;
					 top: 0px !important;
				   }
							   
				   .btn-default-focus {
					 color: $dark;
					 border-color: $brand-color-4;
					 text-decoration: none;
					 outline: 5px auto -webkit-focus-ring-color;
					 outline-offset: -2px;
					 box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
				   }
				   
				   .ui-select-bootstrap .ui-select-toggle {
						width: 100%;
						line-height: 2.5;
						width: 100%;
						padding: 0.5rem 1rem;
						background: none;
						font-size: 0.875rem;
						vertical-align: middle;
						line-height: normal;
				    }
				   
				   .ui-select-bootstrap .ui-select-toggle > .caret {
					 position: absolute;
					 height: 10px;
					 top: 50%;
					 right: 10px;
					 margin-top: -2px;
				   }
				   
				   .input-group > .ui-select-bootstrap.dropdown {
					 position: static;
				   }
				   
				   .input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
					 border-radius: 4px; /* FIXME hardcoded value :-/ */
					 border-top-right-radius: 0;
					 border-bottom-right-radius: 0;
				   }
				   .input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
					 border-radius: 4px !important; /* FIXME hardcoded value :-/ */
					 border-top-right-radius: 0 !important;
					 border-bottom-right-radius: 0 !important;
				   }
				   
				   .ui-select-bootstrap > .ui-select-match > .btn{
					 text-align: left !important;
				   }
				   
				   input[type="search"] {
					border: none;
					padding: 0.5rem 1rem;
					background: none;
					font-size: 0.875rem;
					vertical-align: middle;
					line-height: normal;
					width: 100%;
					box-sizing: inherit;
				   }
				  
				   .ui-select-bootstrap > .ui-select-match > .caret {
					 position: absolute;
					 top: 45%;
					 right: 15px;
				   }
				   
				   .ui-select-bootstrap > .ui-select-choices {
					 width: 100%;
					 height: auto;
					 max-height: 200px;
					 overflow-x: hidden;
					 margin-top: 0px;
					 line-height: 20px;
					 padding: 5px
				   }
				   
				   body > .ui-select-bootstrap.open {
					 z-index: 1000; /* Standard Bootstrap dropdown z-index */
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap {
					 height: auto;
					 padding: 3px 3px 0 3px;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
					 background-color: transparent !important; /* To prevent double background when disabled */
					 border: none;
					 outline: none;
					 height: 1.666666rem;
					 margin-bottom: 3px;
					 color:$brand-color-5;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
					 font-size: 1.6em;
					 line-height: 0.75;
				   }
				   
				   .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
					 outline: 0;
					 margin: 0 3px 3px 0;
				   }
				   
				   .ui-select-multiple .ui-select-match-item {
					 position: relative;
				   }
				   
				   .ui-select-multiple .ui-select-match-item.dropping-before:before {
					 content: "";
					 position: absolute;
					 top: 0;
					 right: 100%;
					 height: 100%;
					 margin-right: 2px;
					 border-left: 1px solid $brand-color-3;
				   }
				   
				   .ui-select-multiple .ui-select-match-item.dropping-after:after {
					 content: "";
					 position: absolute;
					 top: 0;
					 left: 100%;
					 height: 100%;
					 margin-left: 2px;
					 border-right: 1px solid $brand-color-3;
				   }
				   
				   .ui-select-bootstrap .ui-select-choices-row>a {
					   display: block;
					   padding: 3px 20px;
					   clear: both;
					   font-weight: 400;
					   line-height: 1.42857143;
					   color: $dark;
					   white-space: nowrap;
				   }
				   
				   .btn-default {
					   color: $brand-color-5;
					   background-color: $light;
				   }
				   
				   .btn {
					   display: inline-table;
				   }
				   
				   .close {
					   float: right;
					   font-weight: 700;
					   opacity: 0.5;
					   cursor: pointer;
				   }
			   }
        	}
        }
        
        &_total-amount-container {
        	@include clearfix;
	    	float: left;
        	margin-bottom:1rem;
	    	width: 100%;
        }
        
        &_pricing-container {
	    	float: left;
	    	width: 100%;
        }
    }
}

@mixin  make-miscellaneous-pricing-form { 
	@include make-miscellaneous-pricing-form_private; 
}