.total-pricing-info-currency-details-container {
	border: 1px solid $gray-lighting;
	&__total-in-supplier-currency, &__total-in-client-currency, &__output-taxes {
		border: 1px solid $gray-lighting;
		border-bottom: 0;
		@include clearfix;
		width: 100%;
		padding: 0.5rem;
		
		&_label {
			color: $gray;
			float: left;
			font-size: 1.125rem;
		}
		&_amount-container {
			float: right;
			margin-right: 1.5rem;
			.total-in-supplier-currency, .total-in-client-currency {
				&__currency {
					font-size: 0.75rem;
				}
				&__value {
					color: $brand-color-1;
					font-family: $font-family-number;
					font-size: 1.125rem;
				}
			}
		}
		&:first-child {
			margin-top:1rem;
		}
		&:last-child {
			border-bottom: 1px solid $gray-lighting;
		}
	}
	&__content {
		float: right;
		width: 30%;
		padding-left: 2rem;
	}
}
.tax-detail {
	@include clearfix;
	&__header-dark {
		border-bottom: 1px solid $gray-lighting;
		padding-bottom: 0.5rem;
	}
	&__header{
		@include clearfix;
		width: 100%;
		&_total-amount-label{
			float: left;
			font-size: 1.125rem;
		}
		&_price-container{
			float:right;
			.price-container {
				&__currency {
					font-size: 0.75rem;
				}
				&__amount {
					color: $brand-color-1;
					font-family: $font-family-number;
					font-size: 1.125rem;
				}
				&__expand-button {
					float:right;
					font-size:1rem;
					margin:0 0.35rem;
					margin-top: 0.125rem; 
				}
			}
		}
	}
}
.tax-detail-content {
	@include clearfix;
	float:right;
	font-size: .875rem;
	margin-right:1rem;
	width:30%;
	&__headers ,
	&__content {
		@include clearfix;
    	border-bottom: 1px solid $separator-color;
    	padding: 0.5rem;
		
		&_label {
			float: left;
			width: 50%;
			word-wrap: break-word;
		}
		&_amount {
			float: left;
			width: 50%;
			text-align: right;
			font-family: $font-family-number;
		}
	}
	&__headers {
    	color: $gray;
	}
	
	&__content {
    	&:last-child {
	    	border-bottom: none;
    	}
	}
}