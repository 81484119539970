.misc-hotel-info{
	
	&__container{
		@include clearfix();
	    border-top: 1px solid $gray-lighting;
    	padding-top: .625rem;
		@include clearfix();
		
		&_booking-level-details {
			float: left;
			width: 100%;
			
			.booking-level-details {
				&__hotel-info-status,
				&__hotel-info-cancellation-policy-deadline,
				&__hotel-info-invoice-date,
				&__hotel-info-nationality {
					float:left;
			    	min-height: 4.75rem;
					padding: 0 .25rem;
					width:25%;
				}
			}
		}
		
		&_hotel-details {
			float: left;
			width: 100%;
			
			.hotel-details {
				&__hotel-info-check-in-date,
				&__hotel-info-check-out-date,
				&__hotel-info-name,
				&__hotel-info-star-rating,
				&__hotel-info-phone-number,
				&__hotel-info-email-id,
				&__hotel-info-address,
				&__hotel-info-country-city,
				&__hotel-info-country-of-residence {
					float:left;
			    	min-height: 4.75rem;
					padding: 0 .25rem;
					width:25%;
				}
				
				&__hotel-info-address {
			    	min-height: 7.5rem;
					width:50%;
					
					textarea {
						height: 5rem;
					}
				}
				&__hotel-info-country-city {
					width:50%;
					
					&_dropdown {
						.country-city {
							&__country, &__city {
								width: 50%;
				    			float: left;
							}
							&__city {
			    				padding-left: 0.5rem;
							}
						}
					}
				}
				
				&__hotel-info-phone-number {
					input {
						font-family: $font-family-number;
					}
				}
			}
		}
		
		&_room-details {
			float: left;
			width: 100%;
			
			.room-details {
			
				&__hotel-info-room-category,
				&__hotel-info-meal-plan {
					float:left;
			    	min-height: 4.75rem;
					padding: 0 .25rem;
					width:25%;
				}
			}
		}
		
	}
}
.country-selector{
	&__wrapper {
		padding: 0rem;
	    width: 100%;
	    float: left;
	}
}
